<script setup lang="ts">
const { toast } = defineProps<{
    toast: Toast;
}>();

const { toasts } = useToasts();
const el = useTemplateRef('el');

onMounted(() => {
    if (toast.duration) {
        setTimeout(() => {
            delete toasts.value[toast.key];
        }, toast.duration);
    }
});
</script>

<template>
    <div ref="el" class="flex space-x-2  p-4 px-container-gap typo-meta-s">
        <div class="">
            <slot name="icon" />
        </div>
        <div class="">
            <slot />
        </div>
    </div>
</template>
